<script setup lang="ts">
interface TabItem {
  value: string
  label: string
  disabled?: boolean
}
const props = defineProps({
  options: null,
  size: { default: 'default' }
})

const emit = defineEmits(['change'])

const current = defineModel()

const customClass = computed(() => {
  const fontSize = props.size === 'small' ? `text-0.12 font-600` : 'text-0.14 font-700'
  return `${fontSize}`
})

function onChange(item: TabItem) {
  if (item.disabled) {
    return
  }
  current.value = item.value
  emit('change', item.value)
}
</script>

<template>
  <div class="flex select-none gap-x-0.16 font-dm" :class="customClass">
    <div
      v-for="item in options" :key="item.value"
      class="tab-item px-0.12 py-0.06"
      :class="{
        'text-primary current': item.value === current,
        'text-grey1': item.value !== current,
        'cursor-pointer': !item.disabled,
        'cursor-not-allowed': item.disabled,
      }"
      @click="onChange(item)"
    >
      {{ item.label }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.tab-item {
  transition: color 0.1s ease-in;
  &.current {
    background: linear-gradient(180deg, rgba(55, 170, 236, 0.00) 2.5%, rgba(73, 188, 255, 0.25) 88%);
    border-bottom: 2px solid #37AAEC;
  }
}
</style>
