<script setup lang="ts" generic="T extends Record<string, any>">
export interface Column {
  title: string
  key: string
  align?: 'left' | 'right'
  fixed?: boolean
  className?: string
  titleClassName?: string
  width?: number
  minWidth?: number
  span?: number
}

const props = defineProps({
  data: null,
  columns: null,
  columnGap: null,
  loading: { type: Boolean },
  rowKey: { default: 'id' },
  titleRowClass: null,
  contentClass: null,
  rowClass: null
})

const emit = defineEmits(['clickRow'])
</script>

<template>
  <div class="flex flex-col overflow-auto">
    <div class="table-title mb-0.12 flex justify-between" :class="titleRowClass">
      <div
        v-for="item in columns" :key="item.key"
        class="text-0.12 font-600 text-grey1"
        :class="item.titleClassName"
        :style="{
          minWidth: item.minWidth ? `${(item.minWidth) / 400}rem` : 'auto',
          textAlign: item.align,
          width: item.width ? `${(item.width) / 400}rem` : 'auto',
        }"
      >
        <slot :name="`title-${item.key}`" :data="{ column: item }">
          {{ item.title }}
        </slot>
      </div>
    </div>
    <div v-if="data.length || loading" class="relative flex flex-col" :class="contentClass">
      <div v-if="loading" class="absolute inset-0 flex-center bg-[rgba(20,20,22,0.50)]">
        <svg-loading class="animate-spin animate-duration-2000" />
      </div>
      <div
        v-for="(row, index) in data" :key="row[rowKey] || index"
        class="table-content flex items-center justify-between"
        :class="`${rowClass} ${row.rowClass}`"
        @click="emit('clickRow', row)"
      >
        <div
          v-for="item in columns" :key="item.key"
          class="text-0.12 font-400 leading-0.2 text-white1"
          :class="item.titleClassName"
          :style="{
            minWidth: item.minWidth ? `${(item.minWidth) / 400}rem` : 'auto',
            textAlign: item.align,
            width: item.width ? `${(item.width) / 400}rem` : 'auto',
          }"
        >
          <slot :name="item.key" :data="{ row }">
            {{ row[item.key] }}
          </slot>
        </div>
      </div>
    </div>
    <div v-else class="flex-center flex-1 text-0.12" :class="contentClass">
      <slot name="noData">
        No Data
      </slot>
    </div>
  </div>
</template>

<style scoped>

</style>
