<script setup lang="ts">
const current = ref('transfer')
const route = useRoute()

const tabOption = [
  { value: 'transfer', label: 'Transfers' },
  { value: 'pool', label: 'Pools' },
  { value: 'order', label: 'Orders' },
  { value: 'trade', label: 'Trades' },
]

onMounted(() => {
  if (route.query.tab) {
    current.value = route.query.tab as string
  }
})
</script>

<template>
  <AssetModule class="flex flex-col overflow-auto py-0.16">
    <v-tabs v-model="current" :options="tabOption" size="small" class="mb-0.16 ml-0.16 mt-0.06 flex-none px-0.16" />
    <n-config-provider :theme-overrides="selectBorderOverrides" abstract>
      <AssetHistoryTransfer v-if="current === 'transfer'" />
      <AssetHistoryPool v-else-if="current === 'pool'" />
      <AssetHistoryOrder v-else-if="current === 'order'" />
      <AssetHistoryTrade v-else-if="current === 'trade'" />
    </n-config-provider>
  </AssetModule>
</template>

<style scoped>

</style>
