<script setup lang="ts">
const props = defineProps({
  errorMessage: null,
  align: { default: 'right' },
  prefixTop: null,
  prefixBottom: null,
  suffix: null,
  type: null,
  disabled: { type: Boolean },
  locked: { type: Boolean }
})

const emit = defineEmits(['blur', 'change'])
const modelValue = defineModel()
const inputRef = ref<HTMLInputElement>()

const customClass = computed(() => {
  const error = props.errorMessage !== undefined ? 'error' : ''
  const disabled = props.disabled ? 'disabled' : ''
  return `${error} ${disabled}`
})
const inputCustomClass = computed(() => {
  const align = `text-${props.align}`
  return `${align}`
})

function onClickInput() {
  if (inputRef.value) {
    inputRef.value.focus()
  }
}

function onBlur() {
  emit('blur')
}
function onChange() {
  emit('change')
}
</script>

<template>
  <div>
    <div v-if="!locked" class="v-input-wrapper cursor-text bg-black2 text-0.14" :class="customClass" @click="onClickInput">
      <div class="flex flex-col">
        <slot name="prefix">
          <div v-if="prefixTop" class="mb-0.06 text-0.12 font-600 leading-0.12 text-grey1">
            {{ prefixTop }}
          </div>
        </slot>
        <input
          ref="inputRef"
          v-model="modelValue"
          class="v-input flex-none"
          :class="inputCustomClass"
          :type="type"
          :disabled="disabled"
          oninput="value = value.replace(/[^0-9.]/g, '').replace(/^([^.]*)\.([^.]*)\.?/, '\$1.\$2')"
          @blur="onBlur"
          @input="onChange"
        >
        <slot name="prefix">
          <div v-if="prefixBottom" class="mt-0.06 text-0.12 font-600 leading-0.12 text-grey1">
            {{ prefixBottom }}
          </div>
        </slot>
      </div>
      <slot name="suffix">
        <div class="ml-0.04 font-500 text-grey1">
          {{ suffix }}
        </div>
      </slot>
    </div>
    <div v-else class="h-0.8 w-full flex flex-center flex-col border-2 border-black3 rd-0.12 bg-black2">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M6 22C5.45 22 4.97933 21.8043 4.588 21.413C4.19667 21.0217 4.00067 20.5507 4 20V10C4 9.45 4.196 8.97933 4.588 8.588C4.98 8.19667 5.45067 8.00067 6 8H7V6C7 4.61667 7.48767 3.43767 8.463 2.463C9.43833 1.48833 10.6173 1.00067 12 1C13.3833 1 14.5627 1.48767 15.538 2.463C16.5133 3.43833 17.0007 4.61733 17 6V8H18C18.55 8 19.021 8.196 19.413 8.588C19.805 8.98 20.0007 9.45067 20 10V20C20 20.55 19.8043 21.021 19.413 21.413C19.0217 21.805 18.5507 22.0007 18 22H6ZM12 17C12.55 17 13.021 16.8043 13.413 16.413C13.805 16.0217 14.0007 15.5507 14 15C14 14.45 13.8043 13.9793 13.413 13.588C13.0217 13.1967 12.5507 13.0007 12 13C11.45 13 10.9793 13.196 10.588 13.588C10.1967 13.98 10.0007 14.4507 10 15C10 15.55 10.196 16.021 10.588 16.413C10.98 16.805 11.4507 17.0007 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z" fill="#777E91" />
      </svg>
      <div class="mt-0.04 text-caption2b text-grey1">
        The market price is out of your specified price range. Single-asset deposit only.
      </div>
    </div>
    <div v-if="errorMessage" class="mt-0.08 text-0.14 font-500 color-red">
      {{ errorMessage }}
    </div>
  </div>
</template>

<style scoped>
.v-input-wrapper {
  border: 2px solid var(--vessel-color-black-2);
  border-radius: calc(12rem / 400);
  padding: calc(14rem / 400) calc(16rem / 400);
  height: calc(80rem / 400);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;

  &:not(.disabled) {
    &:hover {
      border: 2px solid var(--vessel-color-grey-1);
    }
    &:focus-within, &:active {
      border: 2px solid var(--vessel-color-primary);
    }
  }
  &.error {
    border: 2px solid var(--vessel-color-red) !important;
  }
  .v-input {
    outline: none;
    border: none;
    font-size: calc(24rem / 400);
    line-height: calc(24rem / 400);
    height: calc(28rem / 400);
    width: calc(320rem / 400);
    font-weight: 600;
    background: transparent;
    caret-color: var(--vessel-color-primary);
    color: var(--vessel-color-white-2);
    text-align: left;
    min-width: 0;
    padding: 0;
    &.text-left {
      text-align: left;
    }
  }

  .error-message {
    position: absolute;
    right: 0;
    bottom: calc(54rem / 400);
    max-width: calc(200rem / 400);
    .error-clip {
      position: absolute;
      left: 50%;
      bottom: calc(-12rem / 400);
      transform: translate(-50%, 0);
      width: 0;
      height: 0;
      border: 7px solid transparent;
      border-top: 6px solid var(--vessel-color-grey-1);
    }
  }
}
</style>
