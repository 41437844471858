<script setup lang="ts">
import VTickSpacing from '~/components/v/TickSpacing.vue'

const { poolOverviewList, isLoadingOverviewList, getPoolOverviewList } = useAmm()
const router = useRouter()
const { isSigned } = useKey()
const { isConnected } = useUser()
const { openModal } = useModal()

function addSymbol(symbol: string, ts?: string) {
  if (!isConnected.value) {
    openModal('chooseProvider')
  }
  else if (!isSigned.value) {
    checkAndBindVesselKey()
  }
  else {
    router.push({
      path: '/pools/add',
      query: {
        symbol,
        ts,
      },
    })
  }
}

function jumpPoolOverview(poolId: string) {
  router.push({
    path: `/pools/${poolId}`,
  })
}
onMounted(() => {
  getPoolOverviewList()
})
</script>

<template>
  <div class="flex flex-col items-center px-0.8 py-0.4">
    <div class="text-0.48 text-white2 font-700 leading-.56 font-dm">
      Liquidity Pools
    </div>
    <div class="mt-0.12 text-center text-0.16 text-grey1 font-400">
      Become a liquidity provider to earn from protocol fees! Unlock bonus rewards during Vessel’s incentive phase!
    </div>
    <div class="mt-0.12 flex items-center gap-x-0.24">
      <v-button @click="addSymbol('')">
        <template #prefix>
          <SvgPlus class="mr-0.08" />
        </template>
        Create Position
      </v-button>
      <v-button type="outline" @click="$router.push('/portfolio/pool')">
        View My Position
      </v-button>
    </div>
    <div class="mt-0.24 min-h-4 w-full border-0.4 border-black3 rd-0.12 py-0.32 backdrop-blur-10px">
      <div class="sm:px-0.12">
        <div class="mb-0.14 flex px-0.12 text-0.12 text-grey1 font-600">
          <div class="flex-grow-1 flex-basis-1.3">
            Pool
          </div>
          <div class="flex-grow-1 flex-basis-1 text-right">
            TVL
          </div>
          <div class="flex-grow-1 flex-basis-1 text-right">
            24h Volume
          </div>
          <div class="flex-grow-1 flex-basis-1 text-right">
            24h Fee
          </div>
          <div class="flex flex-grow-1 flex-basis-1 items-center justify-end">
            Est.APR
            <n-tooltip>
              <div class="mr-0.04 w-2 text-0.12">
                Est. APR is based on the average APR over the last 24 hours’ fees and TVL. The actual APR may vary.
              </div>
              <template #trigger>
                <svg class="ml-0.04 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.79948 13.3333C11.745 13.3333 14.1328 10.9455 14.1328 7.99998C14.1328 5.05446 11.745 2.66665 8.79948 2.66665C5.85396 2.66665 3.46615 5.05446 3.46615 7.99998C3.46615 10.9455 5.85396 13.3333 8.79948 13.3333ZM8.79948 14.6666C12.4814 14.6666 15.4661 11.6819 15.4661 7.99998C15.4661 4.31808 12.4814 1.33331 8.79948 1.33331C5.11758 1.33331 2.13281 4.31808 2.13281 7.99998C2.13281 11.6819 5.11758 14.6666 8.79948 14.6666Z" fill="#777E91" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.79948 4.66666C8.43129 4.66666 8.13281 4.96513 8.13281 5.33332C8.13281 5.70151 8.43129 5.99999 8.79948 5.99999C9.16767 5.99999 9.46615 5.70151 9.46615 5.33332C9.46615 4.96513 9.16767 4.66666 8.79948 4.66666ZM8.79948 7.33332C8.43129 7.33332 8.13281 7.6318 8.13281 7.99999V10.6667C8.13281 11.0348 8.43129 11.3333 8.79948 11.3333C9.16767 11.3333 9.46615 11.0348 9.46615 10.6667V7.99999C9.46615 7.6318 9.16767 7.33332 8.79948 7.33332Z" fill="#777E91" />
                </svg>
              </template>
            </n-tooltip>
          </div>
          <div class="flex-grow-1 flex-basis-1 text-right">
            Action
          </div>
        </div>
        <SvgLoading v-if="isLoadingOverviewList" class="mx-auto mt-1 animate-spin" />
        <div v-else>
          <div
            v-for="(item, index) in poolOverviewList" :key="index"
            class="mb-0.12 h-0.72 flex cursor-pointer rd-0.08 px-0.12 py-0.14 text-body2b text-white2 font-500 leading-0.48 hover:bg-black2"
            @click="jumpPoolOverview(item.poolId)"
          >
            <div class="flex flex-grow-1 flex-basis-1.3 items-center">
              <v-icon-pair class="mr-0.08" :base="item.baseTokenName" :quote="item.quoteTokenName" :size="24" />
              <div>
                <div class="text-caption1">
                  {{ item.baseTokenName }}/{{ item.quoteTokenName }}
                </div>
                <div class="flex items-center gap-x-0.04">
                  <VTickSpacing :tick-spacing="item.tickSpacing" :token="item.quoteTokenName" />
                  <div v-if="item.boost" class="h-0.2 flex items-center gap-x-0.02 rd-full bg-#37aaec/20 px-0.06 text-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                      <path d="M4.2994 1.60002C4.21512 1.53689 4.11306 1.50197 4.00777 1.50025C3.90248 1.49853 3.79933 1.5301 3.71304 1.59046C3.62675 1.65081 3.56171 1.73687 3.52721 1.83636C3.49271 1.93586 3.4905 2.0437 3.5209 2.14452C3.6083 2.43612 3.61812 2.74547 3.5494 3.04202C3.48091 3.33682 3.33606 3.60841 3.1294 3.82952C3.11563 3.8444 3.10277 3.86009 3.0909 3.87652C2.7929 4.28502 1.1109 6.67652 2.6204 9.25752L2.6354 9.28202C3.00208 9.83348 3.5043 10.2815 4.0939 10.583C4.67639 10.8813 5.32603 11.0241 5.9799 10.9975C6.67517 11.0097 7.36175 10.8417 7.9729 10.51C8.59468 10.1719 9.11833 9.67855 9.4929 9.07802C10.1434 7.97802 10.0849 6.80002 9.7869 5.85752C9.4954 4.93352 8.9469 4.15052 8.4834 3.80652C8.41058 3.7526 8.32447 3.71948 8.23428 3.7107C8.14409 3.70193 8.05322 3.71783 7.97136 3.7567C7.88951 3.79557 7.81976 3.85596 7.76957 3.9314C7.71937 4.00684 7.69063 4.09451 7.6864 4.18502C7.6529 4.90052 7.5049 5.46052 7.2894 5.90052C7.1784 4.69702 6.7259 3.80252 6.1774 3.13852C5.6039 2.44352 4.8954 1.98852 4.5159 1.74452C4.44254 1.69878 4.37051 1.65092 4.2999 1.60102L4.2994 1.60002Z" fill="url(#paint0_linear_32159_35455)" />
                      <defs>
                        <linearGradient id="paint0_linear_32159_35455" x1="6.00059" y1="1.50018" x2="6.00059" y2="15.1783" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#37AAEC" />
                          <stop offset="1" stop-color="#19F2FF" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <div class="text-hairline2">
                      {{ item.boost.boost }}x
                    </div>
                  </div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <g clip-path="url(#clip0_31474_63259)">
                      <path d="M18.6615 13.3949L18.664 2.27951C18.6464 1.34945 17.8938 0.600311 16.9404 0.600311H5.09125C2.53612 0.64225 0.476562 2.68574 0.476562 5.20207C0.476562 6.05153 0.708389 6.77518 1.06537 7.41002C1.36271 7.93878 1.83476 8.43711 2.3043 8.81209C2.43281 8.91488 2.37233 8.87623 2.77383 9.118C3.3282 9.45104 3.96488 9.61715 3.96488 9.61715L3.95984 16.264C3.97328 16.5782 4.00856 16.8808 4.08835 17.1612C4.35294 18.0962 5.01482 18.8174 5.9186 19.166C6.29574 19.3116 6.72328 19.4103 7.17601 19.4127L16.6397 19.4465C18.5271 19.4465 20.0575 17.9465 20.0575 16.0955C20.0575 14.9878 19.509 14.0051 18.6632 13.3958L18.6615 13.3949Z" fill="#FFEEDA" />
                      <path d="M18.8905 16.1679C18.851 17.3545 17.8582 18.3051 16.6377 18.3051L10.123 18.2804C10.6438 17.6925 10.9571 16.9277 10.9571 16.0955C10.9571 14.7855 10.16 13.8859 10.16 13.8859H16.6377C17.8825 13.8859 18.8913 14.8752 18.8913 16.0955V16.1679H18.8905Z" fill="#EBC28E" />
                      <path d="M2.91746 7.86556C2.1699 7.17234 1.64073 6.27353 1.64073 5.20121L1.64241 5.08773C1.70205 3.2671 3.23412 1.80171 5.09209 1.74332L16.9413 1.74168C17.2521 1.75319 17.5015 1.97193 17.5015 2.27948V12.0932C17.7753 12.1417 17.9055 12.1746 18.1684 12.2683C18.3734 12.3415 18.6649 12.5027 18.6649 12.5027V2.2803C18.6472 1.35025 17.8946 0.601105 16.9413 0.601105H5.09125C2.53612 0.642221 0.476562 2.68571 0.476562 5.20204C0.476562 6.66249 1.15608 7.9075 2.26986 8.7841C2.34377 8.84249 2.41769 8.91897 2.6176 8.91814C2.96702 8.91732 3.21144 8.64924 3.19716 8.35156C3.1854 8.1065 3.07957 8.01687 2.91746 7.86638V7.86556Z" fill="#190602" />
                      <path d="M16.639 12.7446H7.34413C6.72005 12.7495 6.21608 13.2462 6.21608 13.8589V15.1639C6.23456 15.7683 6.76037 16.2864 7.38193 16.2864H8.07237V15.1639H7.38193V13.886H7.75907C8.93164 13.886 9.79511 14.955 9.79511 16.0956C9.79511 17.1062 8.85604 18.4014 7.27694 18.2978C5.8759 18.2057 5.1233 16.987 5.1233 16.0956V5.00566C5.1233 4.50486 4.70837 4.09863 4.196 4.09863H3.26953V5.23016H3.95829V16.0956C3.92301 18.3052 5.56596 19.4195 7.27694 19.4195L16.6382 19.4466C18.5256 19.4466 20.0559 17.9467 20.0559 16.0956C20.0559 14.2445 18.5256 12.7446 16.6382 12.7446H16.639ZM18.8918 16.168C18.8523 17.3546 17.8595 18.3052 16.639 18.3052L10.1244 18.2805C10.6451 17.6926 10.9584 16.9278 10.9584 16.0956C10.9584 14.7856 10.1613 13.886 10.1613 13.886H16.639C17.8838 13.886 18.8926 14.8753 18.8926 16.0956V16.168H18.8918Z" fill="#190602" />
                      <path d="M14.1369 4.30246H7.13086V5.43399H14.1369C14.4561 5.43399 14.7148 5.18071 14.7148 4.86822C14.7148 4.55574 14.4561 4.30246 14.1369 4.30246Z" fill="#190602" />
                      <path d="M14.1369 9.64343H7.13086V10.775H14.1369C14.4561 10.775 14.7148 10.5217 14.7148 10.2092C14.7148 9.89671 14.4561 9.64343 14.1369 9.64343Z" fill="#190602" />
                      <path d="M15.3691 6.97256H7.13086V8.10409H15.3699C15.6891 8.10409 15.9478 7.85081 15.9478 7.53833C15.9478 7.22584 15.6891 6.97256 15.3699 6.97256H15.3691Z" fill="#190602" />
                    </g>
                    <defs>
                      <clipPath id="clip0_31474_63259">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
            <div class="flex-grow-1 flex-basis-1 text-right">
              ${{ formatNumberWithUnit(+item.quoteTvlValue + +item.baseTvlValue) }}
            </div>
            <div class="flex-grow-1 flex-basis-1 text-right">
              ${{ formatNumberWithUnit(item.tradeVolume24h) }}
            </div>
            <div class="flex-grow-1 flex-basis-1 text-right">
              ${{ formatNumberWithUnit(item.feeValue24h) }}
            </div>
            <div class="flex-grow-1 flex-basis-1 text-right">
              {{ getRatio(item.apr) }}
            </div>
            <div
              class="flex flex-grow-1 flex-basis-1 cursor-pointer items-center justify-end text-right text-button2 text-primary"
              @click.stop="addSymbol(`${item.baseTokenName}${item.quoteTokenName}`, item.tickSpacing)"
            >
              + Add
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
