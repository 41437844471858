import type { InternalAxiosRequestConfig } from 'axios'
import hmacSHA256 from 'crypto-js/hmac-sha256'
import Base64 from 'crypto-js/enc-base64'
import encHex from 'crypto-js/enc-hex'

// @ts-expect-error no type
import { buildPoseidon } from 'circomlibjs'
import secp256k1 from 'secp256k1'
import BN from 'bignumber.js'
import type { PlaceOrderRequest } from '~/service/modules/order'
import type { AddPoolReq } from '~/service/modules/amm'

const { apiKey } = useKey()

export function getApiHeader(config: InternalAxiosRequestConfig): Record<string, string> {
  const { address } = useUser()
  if (apiKey.value && apiKey.value.secret) {
    let timestamp
    if (!config.headers['VESSEL-TIMESTAMP']) {
      timestamp = new Date().getTime()
    }
    else {
      timestamp = config.headers['VESSEL-TIMESTAMP']
    }
    const paramKeyList = config.params ? Object.keys(config.params).filter(i => config.params[i] !== undefined && config.params[i] !== null) : []
    const params = config.params && (paramKeyList.length) ? `?${paramKeyList.map(i => `${i}=${config.params[i]}`).join('&')}` : ''
    const data = config.data ? JSON.stringify(config.data) : ''
    const signature = Base64.stringify(hmacSHA256(
      `${timestamp}${config.method?.toUpperCase()}${config.baseURL}${config.url}${params}${data}`,
      encHex.parse(apiKey.value.secret.slice(2)),
    ))

    return {
      'VESSEL-TIMESTAMP': `${timestamp}`,
      'VESSEL-API-KEY': apiKey.value.key,
      'VESSEL-PASSPHRASE': apiKey.value.passphrase,
      'VESSEL-SIGNATURE': signature,
      'VESSEL-ADDRESS': address.value || '',
    }
  }

  return {}
}

export async function signOrder(
  data: Omit<PlaceOrderRequest, 'signature'>,
) {
  const { symbolList } = useSymbol()
  const symbolItem = symbolList.value.find(i => i.symbol === data.symbol)
  const { userInfo } = useUser()
  const { vesselKey } = useKey()
  console.log(userInfo)

  const { takerFee, makerFee } = getUserFee(data.symbol)
  console.log(takerFee, makerFee)

  const poseidon = await buildPoseidon()
  const isBuy = data.side === 'BUY'

  const buyAssetId = BN(symbolItem[isBuy ? 'baseAssetId' : 'quoteAssetId']).toFixed()
  const sellAssetId = BN(symbolItem[isBuy ? 'quoteAssetId' : 'baseAssetId']).toFixed()
  const takerFeeRate = BN(takerFee).multipliedBy(BN('1e6')).toFixed()
  const makerFeeRate = BN(makerFee).multipliedBy(BN('1e6')).toFixed()
  const timestamp = BN(data.clientOrderId).toFixed()
  const buyAssetAmount = BN(data.quantity).multipliedBy(BN(isBuy ? 1 : data.price))
    .multipliedBy(`1e${symbolItem[isBuy ? 'baseOnChainDecimal' : 'quoteOnChainDecimal']}`).toFixed()
  const sellAssetAmount = BN(data.quantity).multipliedBy(BN(isBuy ? data.price : 1))
    .multipliedBy(`1e${symbolItem[isBuy ? 'quoteOnChainDecimal' : 'baseOnChainDecimal']}`).toFixed()
  const x = BN(buyAssetId)
    .plus(BN(sellAssetId).multipliedBy(BN(2).pow(BN(32))))
    .plus(BN(takerFeeRate).multipliedBy(BN(2).pow(BN(64))))
    .plus(BN(makerFeeRate).multipliedBy(BN(2).pow(BN(96))))
    .plus(BN(timestamp).multipliedBy(BN(2).pow(BN(128)))).toFixed()

  const y = BN(buyAssetAmount).plus(BN(sellAssetAmount).multipliedBy(BN(2).pow(BN(126)))).toFixed()

  const z = BN(data.clientOrderId).toFixed()

  const xyHash = poseidon([x, y])
  const xyzHash = poseidon([xyHash, z])

  const pubKeyX = BigInt(`0x${vesselKey.value.publicKey.slice(2, 66)}`)
  const pubKeyY = BigInt(`0x${vesselKey.value.publicKey.slice(66)}`)

  const Fq = BigInt('21888242871839275222246405745257275088548364400416034343698204186575808495617')
  const u = poseidon([pubKeyX % Fq, pubKeyY % Fq])

  const hash = poseidon([xyzHash, u])
  const signHash = hexToByte(poseidon.F.toString(hash, 16).padStart(64, '0'))
  const sigObj = secp256k1.ecdsaSign(signHash, hexToByte(vesselKey.value.privateKey))
  console.log('buyAssetId', buyAssetId)
  console.log('sellAssetId', sellAssetId)
  console.log('takerFeeRate', takerFeeRate)
  console.log('makerFeeRate', makerFeeRate)
  console.log('timestamp', timestamp)
  console.log('buyAssetAmount', buyAssetAmount)
  console.log('sellAssetAmount', sellAssetAmount)
  console.log('x', x)
  console.log('y', y)
  console.log('z', z)
  console.log('pubX', pubKeyX)
  console.log('pubY', pubKeyY)
  console.log('u', poseidon.F.toString(u))
  console.log('xyHash', poseidon.F.toString(xyHash))
  console.log('xyzHash', poseidon.F.toString((xyzHash)))
  console.log('hash', poseidon.F.toString(hash), 'pk', vesselKey.value.privateKey)
  console.log('sig', byteToHex(sigObj.signature))
  return {
    signature: byteToHex(sigObj.signature),
    timestamp,
  }
}

export async function signPool(data: Omit<AddPoolReq, 'signature'> & { timestamp: number; addType: number }, symbol: string) {
  console.log(data)

  const { vesselKey } = useKey()
  const { symbolList } = useSymbol()
  const currentSymbol = symbolList.value.find(i => i.symbol === symbol)
  console.log(data)
  const x = BN(data.poolId)
    .plus(BN(data.tickIndexL).multipliedBy(BN(2).pow(BN(32))))
    .plus(BN(data.tickIndexR).multipliedBy(BN(2).pow(BN(96))))
    .plus(BN(data.timestamp).multipliedBy(BN(2).pow(BN(160))))
    .plus(BN(data.addType).multipliedBy(BN(2).pow(BN(224)))).toFixed()

  const baseAmount = BN(data.baseAmount).multipliedBy(`1e${currentSymbol.baseOnChainDecimal}`).toFixed()
  const quoteAmount = BN(data.quoteAmount).multipliedBy(`1e${currentSymbol.quoteOnChainDecimal}`).toFixed()
  console.log(baseAmount, quoteAmount)

  const y = BN(baseAmount)
    .plus(BN(quoteAmount).multipliedBy(BN(2).pow(BN(126)))).toFixed()
  const z = BN(data.nonce).toFixed()
  console.log(x, y, z)

  const poseidon = await buildPoseidon()
  const xyHash = poseidon([x, y])
  const hash = poseidon([xyHash, z])
  console.log(poseidon.F.toString(xyHash))
  console.log(poseidon.F.toString(hash))
  const signHash = hexToByte(poseidon.F.toString(hash, 16).padStart(64, '0'))
  const sigObj = secp256k1.ecdsaSign(signHash, hexToByte(vesselKey.value.privateKey))
  return byteToHex(sigObj.signature)
}
