<script setup lang="ts">
const props = defineProps({
  options: null,
  disabled: { type: Boolean }
})
const emits = defineEmits(['change'])
const modelValue = defineModel()
function onClick(i: string) {
  if (!props.disabled) {
    modelValue.value = i
    emits('change')
  }
}
</script>

<template>
  <div class="flex overflow-hidden border-1 border-black3 rd-0.08 bg-black2">
    <div
      v-for="i in options" :key="i"
      class="px-0.08 py-0.04 text-0.14 font-700 font-dm"
      :class="{
        'bg-black3 text-white2': i === modelValue,
        'bg-black2 text-grey1 hover:text-grey2': i !== modelValue,
        'cursor-pointer': !disabled,
        'cursor-not-allowed': disabled,
      }"
      @click="onClick(i)"
    >
      {{ i }}
    </div>
  </div>
</template>

<style scoped>

</style>
