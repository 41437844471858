<script setup lang="ts">
import { useAsyncValidator } from '@vueuse/integrations/useAsyncValidator'
import BN from 'bignumber.js'
import { usePutOrder } from '../limit'
import type { PlaceOrderRequest } from '~/service/modules/order'

const props = defineProps({
  
})

const emit = defineEmits(['submit'])
const { currentSymbolItem, currentTickerItem } = useSymbol()
const { assetBalance, symbolPrice, tradeList } = useWs()
const { isSigned } = useKey()
const { isConnected, preference, updatePreference } = useUser()
const notification = useNotification()

const isFilledPrice = ref(false)
const isValidate = ref(false)
const showConfirmModal = ref(false)
const isPriceNotExist = ref(false)
const fieldEmpty = ref<Record<string, boolean>>({})
const isSubmit = ref(false)

const baseAsset = computed(() => currentSymbolItem.value?.base)
const quoteAsset = computed(() => currentSymbolItem.value?.quote)

const { run: placeOrder, isLoading: isLoadingSubmit } = useHttp(vesselApi.order.placeOrder)

const { type } = usePutOrder()

interface MarketForm {
  price: string
  amount: string
  total: string
  percent: number
}

type MarketFormWithType = Record<'buy' | 'sell', MarketForm>
function initForm(): MarketForm {
  return {
    price: '',
    amount: '',
    total: '',
    percent: 0,
  }
}

const form = ref<MarketFormWithType>({
  buy: initForm(),
  sell: initForm(),
})

const { pass, errorFields, execute: validateFields } = useAsyncValidator(form, computed(() => ({
  [type.value]: {
    type: 'object',
    fields: {
      amount: [
        // {
        //   validator(rule: any, value: any) {
        //     return +value > 0
        //   },
        //   required: true,
        //   message: 'Amount is required',
        // },
        {
          validator(rule: any, value: any) {
            if (+currentTickerItem.value.baseAssetPrecision >= 0) {
              return true
            }
            if (value.includes('.')) {
              return false
            }
            const zeroNum = value.match(/^.*[123456789](0*)$/)
            if (zeroNum[1].length < -currentTickerItem.value.baseAssetPrecision) {
              return false
            }
            return true
          },
          message: `Enter an integral multiple of ${formatNumber(10 ** -currentTickerItem.value.baseAssetPrecision, 0)} or it will be rounded to ${formatNumber(10 ** -currentTickerItem.value.baseAssetPrecision, 0)}`,
        },
        // {
        //   pattern: currentTickerItem.value.baseAssetPrecision > 0 ? new RegExp(`^\\d+(\\.\\d{1,${currentTickerItem.value.baseAssetPrecision}})?$`) : /^\d+$/,
        //   message: `Number decimals must be under ${currentTickerItem.value.baseAssetPrecision}`,
        // },
      ],
    },
  },
})), {
  manual: false,
})

const availableBalance = computed(() => {
  return (assetBalance.value?.find(i => i.assetName === (type.value === 'buy' ? quoteAsset.value : baseAsset.value))?.available) || 0
})
const maxAmount = computed(() => type.value === 'buy' ? (form.value[type.value].price ? +availableBalance.value / +form.value[type.value].price : 0) : availableBalance.value)
const hideOrderConfirmation = computed(() => !preference.value.orderConfirmation)

function onChangePrice() {
  fieldEmpty.value = {}
  const { price, amount } = form.value[type.value]
  if (amount) {
    form.value[type.value].total = `${+BN(price).multipliedBy(BN(amount)).decimalPlaces(+currentTickerItem.value.quoteAssetPrecision).toFixed()}`
    form.value[type.value].percent = BN(amount).dividedBy(BN(maxAmount.value)).multipliedBy(BN(100)).toNumber()
  }
}

function onChangeAmount() {
  fieldEmpty.value = {}
  const { price, amount } = form.value[type.value]
  if (price && amount) {
    form.value[type.value].total = `${+BN(price).multipliedBy(BN(amount)).decimalPlaces(+currentTickerItem.value.quoteAssetPrecision).toFixed()}`
    form.value[type.value].percent = BN(amount).dividedBy(BN(maxAmount.value)).multipliedBy(BN(100)).toNumber()
  }
}
function onChangeTotal() {
  fieldEmpty.value = {}
  const { price, amount, total } = form.value[type.value]
  if (price && total) {
    const realAmount = `${+BN(total).dividedBy(BN(price)).toFixed(+currentTickerItem.value.baseAssetPrecision)}`
    form.value[type.value].amount = realAmount
    form.value[type.value].percent = BN(realAmount).dividedBy(BN(maxAmount.value)).multipliedBy(BN(100)).toNumber()
  }
}
function onChangeAmountPercent(percent: number) {
  fieldEmpty.value = {}
  const { price } = form.value[type.value]
  if (price) {
    form.value[type.value].amount = `${BN(maxAmount.value).multipliedBy(percent).dividedBy(BN(100)).decimalPlaces(+currentTickerItem.value.baseAssetPrecision, BN.ROUND_FLOOR).toFixed()}`
    form.value[type.value].total = `${+BN(price).multipliedBy(form.value[type.value].amount).toFixed(+currentTickerItem.value.quoteAssetPrecision)}`
  }
}

function getErrorMessage(type: string, field: string) {
  return errorFields.value[`${type}.${field}`]?.[0]?.message || (fieldEmpty.value[`${type}.${field}`] && `${field.slice(0, 1).toUpperCase()}${field.slice(1)} is required`) || ''
}

const disableSubmit = computed(() => {
  if (type.value === 'buy' && +form.value[type.value].total > +availableBalance.value) {
    return true
  }
  if (type.value === 'sell' && +form.value[type.value].amount > +availableBalance.value) {
    return true
  }
  return isPriceNotExist.value
})

function validateEmptyFields() {
  const valueKeys = ['amount', 'price', 'total']
  for (const key of valueKeys) {
    if (!form.value[type.value][key as keyof typeof form.value.sell]) {
      fieldEmpty.value[`${type.value}.${key}`] = true
    }
  }
}

async function preSubmitOrder() {
  isValidate.value = true
  showConfirmModal.value = false
  await validateFields()
  validateEmptyFields()
  if (disableSubmit.value || Object.keys(errorFields.value).length > 0 || Object.keys(fieldEmpty.value).length > 0) {
    return
  }
  if (preference.value.orderConfirmation) {
    showConfirmModal.value = true
  }
  else {
    submitOrder()
  }
}

async function submitOrder() {
  if (isSubmit.value) {
    return
  }
  showConfirmModal.value = false
  isSubmit.value = true
  let realPrice = +form.value[type.value].price
  if (type.value === 'buy') {
    realPrice = (Math.min(
      realPrice * 1.05,
      +floorTo(+availableBalance.value / +form.value[type.value].amount, +currentTickerItem.value.quoteAssetPrecision - +currentTickerItem.value.baseAssetPrecision),
    ))
  }
  else {
    realPrice = realPrice * 0.95
  }
  const order: Omit<PlaceOrderRequest, 'signature'> = {
    symbol: currentSymbolItem.value.symbol,
    side: type.value.toUpperCase() as 'SELL' | 'BUY',
    type: 'MARKET',
    timeInForce: 'GTC',
    quantity: +form.value[type.value].amount,
    price: +realPrice.toFixed(+currentTickerItem.value.quoteAssetPrecision - +currentTickerItem.value.baseAssetPrecision),
    clientOrderId: `${new Date().getTime()}`,
  }
  const { signature, timestamp } = await signOrder(order)

  placeOrder({
    ...order,
    signature,
  }, {
    headers: {
      'VESSEL-TIMESTAMP': timestamp,
    },
  }).then((res) => {
    if (res.data.error) {
      return
    }
    emit('submit')
    form.value.buy.amount = ''
    form.value.sell.amount = ''
    onChangeAmount()
    if (preference.value.tradeNotification) {
      notification.success({
        title: 'Market Order Confirmation',
        duration: 2000,
        content: () => h('div', {}, {
          default: () => [
            h('div', {}, { default: () => `Your ${type.value.toLowerCase()} market order for ${formatNumber(res.data.origQty, +currentTickerItem.value.baseAssetPrecision)} ${baseAsset.value} has been placed successfully.` }),
          ],
        }),
      })
    }
  }).finally(() => {
    isSubmit.value = false
  })
}

function handleChangePreference() {
  preference.value.orderConfirmation = !preference.value.orderConfirmation
  updatePreference()
}

watch(tradeList, () => {
  if (tradeList.value?.[0]?.price) {
    isPriceNotExist.value = false
    form.value.buy.price = (+tradeList.value?.[0]?.price).toFixed(2)
    form.value.sell.price = (+tradeList.value?.[0]?.price).toFixed(2)
    onChangePrice()
  }
  else {
    isPriceNotExist.value = true
    form.value.buy.amount = ''
    form.value.sell.amount = ''
    form.value.buy.total = ''
    form.value.sell.total = ''
  }
}, { immediate: true })
</script>

<template>
  <div class="flex flex-col gap-y-0.12">
    <v-input
      model-value="Market" prefix="Price"
      disabled
    />
    <v-input
      v-model="form[type].amount" prefix="Amount"
      :suffix="baseAsset" :error-message="getErrorMessage(type, 'amount')"
      :precision="+currentTickerItem?.baseAssetPrecision" @input="onChangeAmount"
    />
    <v-progress v-model="form[type].percent" @change="onChangeAmountPercent" />
    <v-input v-model="form[type].total" prefix="Total" :suffix="quoteAsset" @input="onChangeTotal" />
    <div class="flex flex-col gap-y-0.1 text-0.12">
      <div class="flex">
        <div class="mr-0.04 text-grey1">
          Available
        </div>
        <div>{{ availableBalance }} {{ type === 'buy' ? quoteAsset : baseAsset }}</div>
      </div>
      <!-- <div class="flex items-center">
        <div class="mr-0.04 text-grey1">
          Est.Fee
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="mr-0.04">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00065 13.3333C10.9462 13.3333 13.334 10.9455 13.334 8C13.334 5.05448 10.9462 2.66667 8.00065 2.66667C5.05513 2.66667 2.66732 5.05448 2.66732 8C2.66732 10.9455 5.05513 13.3333 8.00065 13.3333ZM8.00065 14.6667C11.6825 14.6667 14.6673 11.6819 14.6673 8C14.6673 4.3181 11.6825 1.33334 8.00065 1.33334C4.31875 1.33334 1.33398 4.3181 1.33398 8C1.33398 11.6819 4.31875 14.6667 8.00065 14.6667Z" fill="#777E91" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.42158 6.33381C7.23722 6.65252 6.8294 6.76143 6.51069 6.57707C6.19199 6.39271 6.08308 5.98489 6.26744 5.66618C6.61224 5.07012 7.25835 4.66666 7.99947 4.66666C9.10404 4.66666 9.99947 5.56209 9.99947 6.66666C9.99946 7.39848 9.55055 7.99599 8.99947 8.33333C8.55681 8.6043 8.8328 9.33333 7.99772 9.33333C7.62953 9.33333 7.33105 9.03485 7.33105 8.66666C7.33105 8.64326 7.33226 8.62013 7.33461 8.59735C7.3531 8.24477 7.51032 7.95789 7.6815 7.74929C7.86228 7.52898 8.09996 7.34914 8.2817 7.2411C9.29284 6.64 8.05958 5.41026 7.42158 6.33381ZM7.99772 10C7.62953 10 7.33105 10.2985 7.33105 10.6667C7.33105 11.0349 7.62953 11.3333 7.99772 11.3333C8.36591 11.3333 8.66439 11.0349 8.66439 10.6667C8.66439 10.2985 8.36591 10 7.99772 10Z" fill="#777E91" />
        </svg>
        <div>{{ availableBalance }} USDT</div>
      </div> -->
      <div class="w-full bg-black2 pt-0.01" />
    </div>

    <common-connect-wallet-button v-if="!isConnected" />
    <common-sign-for-trading-button v-else-if="!isSigned" />
    <v-button
      v-else type="primary" :bg="type === 'sell' ? 'red' : 'green'"
      :disabled="disableSubmit" :loading="isLoadingSubmit"
      @click="preSubmitOrder"
    >
      <span class="mr-0.04 capitalize">{{ type }}</span> {{ baseAsset }}
    </v-button>
    <v-modal v-model:show="showConfirmModal" title="Order Confirmation" :z-index="201">
      <div class="text-grey1 font-400">
        <div class="text-0.16">
          <span class="text-white2">{{ baseAsset }}</span><span>/{{ quoteAsset }}</span>
        </div>
        <div class="grid grid-cols-2 mt-0.2 text-0.12">
          <div>
            <div>Side</div>
            <div class="text-0.14 font-500 leading-0.24" :class="type === 'sell' ? 'text-red' : 'text-green'">
              {{ type === 'sell' ? 'Sell' : 'Buy' }}
            </div>
          </div>
          <div>
            <div>Type</div>
            <div class="text-0.14 text-white2 font-500 leading-0.24">
              Market
            </div>
          </div>
          <div>
            <div>Price</div>
            <div class="text-0.14 text-white2 font-500 leading-0.24">
              Market
            </div>
          </div>
          <div>
            <div>Amount</div>
            <div class="text-0.14 text-white2 font-500 leading-0.24">
              {{ form[type].amount }} {{ baseAsset }}
            </div>
          </div>
        </div>
        <n-checkbox
          :checked="hideOrderConfirmation" class="mt-0.32 text-0.12 font-600"
          @update:checked="handleChangePreference"
        >
          Don't show again
        </n-checkbox>
      </div>
      <template #footer>
        <div class="mt-0.32 flex gap-x-0.16">
          <v-button type="outline" class="flex-1" @click="showConfirmModal = false">
            Cancel
          </v-button>
          <v-button class="flex-1" @click="submitOrder">
            Confirm
          </v-button>
        </div>
      </template>
    </v-modal>
  </div>
</template>

<style scoped>

</style>
