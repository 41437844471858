<script setup lang="ts">
const props = defineProps({
  showTitle: { type: Boolean }
})
const { tradeList } = useWs()
const { currentSymbolItem } = useSymbol()

const maxTotal = computed(() => Math.max(...tradeList.value.map(i => +i.quantity)) || 0)
</script>

<template>
  <div :style="{ '--max': maxTotal }" class="flex flex-col">
    <div v-if="showTitle" class="ml-0.1 mt-0.1 text-0.14 font-700 font-dm">
      Last Trade
    </div>
    <!-- Title -->
    <div class="mt-0.08 flex flex-none px-0.1 text-0.12 font-500 text-grey1">
      <div class="flex-1 text-left">
        Price({{ currentSymbolItem?.quote }})
      </div>
      <div class="flex-1 text-right">
        Amount({{ currentSymbolItem?.base }})
      </div>
      <div class="flex-1 text-right">
        Time
      </div>
    </div>

    <div
      class="mt-0.08 flex flex-1 flex-col gap-y-0.04 overflow-auto px-0.1"
    >
      <div
        v-for="(item, index) in tradeList" :key="index"
        class="list-bg flex text-0.12 font-500 text-grey1"
        :style="{
          '--current': item.quantity,
          '--color': item.isBuyerMaker ? 'var(--vessel-color-red)' : 'var(--vessel-color-green)',
        }"
      >
        <div class="list-price flex-1 text-left text-red">
          {{ item.price }}
        </div>
        <div class="flex-1 text-right">
          {{ item.quantity }}
        </div>
        <div class="flex-1 text-right">
          {{ dayjs(new Date(+item.tradeTime)).format('HH:mm:ss') }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.list-price {
  color: var(--color)
}

.list-bg {
  width: 100%;
  --ratio: calc(var(--current) / var(--max) * 100%);
  background: linear-gradient(to left,
  color-mix(in srgb, var(--color), transparent 90%) var(--ratio),
  transparent 0%)
}
</style>
