import BigNumber from 'bignumber.js'

export function formatNumber(number: number | string | undefined, fixed = 2) {
  if (number === undefined) {
    return '-'
  }
  if (fixed >= 0) {
    return `${+number >= 0 ? '' : '-'}${Math.abs(Number(number)).toFixed(fixed).replace(/(^|\s)\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))}`
  }
  else {
    return `${`${+number >= 0 ? '' : '-'}${Math.abs(Number(number))}`.replace(/(^|\s)\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))}`
  }
}

export function formatNumberWithUnit(number: number | string | undefined, fixed = 2) {
  if (Number.isNaN(number)) {
    return '-'
  }
  let _number = Number(number)
  let unit = ''
  if (_number > 1000000000) {
    _number = _number / 1000000000
    unit = 'T'
  }
  if (_number > 1000000) {
    _number = _number / 1000000
    unit = 'M'
  }
  if (_number > 1000) {
    _number = _number / 1000
    unit = 'k'
  }
  return `${+number >= 0 ? '' : '-'}${Math.abs(Number(_number)).toFixed(fixed).replace(/(^|\s)\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))}${unit}`
}

export function formatInt(number: number | string | undefined) {
  if (number === undefined) {
    return '-'
  }
  return `${+number >= 0 ? '' : '-'}${`${Math.abs(Number(number))}`.replace(/(^|\s)\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))}`
}

export function shortenAddress(address: string): string {
  return address ? `${address.slice(0, 6)}...${address.slice(-4)}` : ''
}

export function openUrl(url: string) {
  window.open(url, '_blank')
}

export function getRatio(number: number | string, fixed = 2) {
  if (Number.isNaN(number)) {
    return '-'
  }
  return `${formatNumber(BigNumber(number).multipliedBy(BigNumber(100)).toNumber(), fixed)}%`
}

export function byteToHex(value: Uint8Array) {
  return `0x${Array.from(value).map(byte => byte.toString(16).padStart(2, '0')).join('')}`
}

export function hexToByte(value: string) {
  let _value = value
  if (value.startsWith('0x')) {
    _value = value.slice(2)
  }
  return new Uint8Array(_value.match(/.{1,2}/g).map(byte => Number.parseInt(byte, 16)))
}

export function multiplyByPower(decimal: number | string, power: number) {
  const value = new BigNumber(decimal)
  const multiplier = new BigNumber(10).exponentiatedBy(power)
  const result = value.multipliedBy(multiplier)
  return result.toFixed()
}

export function dividedByPower(decimal: number | string | bigint, power: number) {
  const value = new BigNumber(decimal.toString())
  const multiplier = new BigNumber(10).exponentiatedBy(power)
  const result = value.dividedBy(multiplier)
  return result.toFixed()
}

export function floorTo(origin: number, decimal: number) {
  const num = multiplyByPower(origin, decimal)
  return dividedByPower(BigNumber(num).toFixed(0, BigNumber.ROUND_FLOOR), decimal)
}

export function transformType<T>(obj: Record<string, string | boolean>, numberKeys: string[]): T {
  const convertFunc = (key: string, value: string | boolean) => {
    if (numberKeys.includes(key)) {
      return Number(value)
    }
    return value
  }
  const result: any = {}
  for (const key in obj) {
    result[key] = convertFunc(key, obj[key])
  }
  return result as T
}

export function capitalizeFirstLetter(input: string) {
  return input.length > 1 ? `${input.charAt(0).toUpperCase()}${input.slice(1).toLowerCase()}` : input.toUpperCase()
}
