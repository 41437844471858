<script setup lang="ts">
type ButtonType = 'primary' | 'plain' | 'outline'

const props = defineProps({
  type: { default: 'primary' },
  size: { default: 'default' },
  bg: null,
  color: null,
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean }
})

const customClass = computed(() => {
  const buttonType = `${props.type}-button`
  const buttonSize = `${props.size}-size`
  const buttonDisabled = props.disabled ? 'disabled' : ''
  return `${buttonType} ${buttonSize} ${buttonDisabled}`
})

const customStyle = computed(() => {
  return {
    '--bg': `var(--vessel-color-${props.bg || 'primary'})`,
    '--color': `var(--vessel-color-${props.color || 'white-2'})`,
  }
})
</script>

<template>
  <div
    class="button font-dm"
    :class="customClass" :style="customStyle"
  >
    <slot name="prefix">
      <svg-loading-in-button v-if="loading" class="mr-0.12 animate-spin animate-duration-2000" />
    </slot>
    <slot />
    <slot name="suffix" />
  </div>
</template>

<style scoped lang="scss">
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10000px;
  cursor: pointer;
  color: var(--color);
  user-select: none;
  font-weight: 600;

  &.primary-button {
    background-image: linear-gradient(0, var(--bg), var(--bg));
    background-blend-mode: color;
    &:hover:not(.disabled) {
      background-image: linear-gradient(0, var(--bg), var(--bg)), linear-gradient(0, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15));
    }
  }
  &.outline-button {
    border: 2px var(--vessel-color-black-3) solid;
    &:hover:not(.disabled) {
      background: rgba(119, 126, 144, 0.08);
    }
  }

  &.default-size {
    font-size: calc(14rem / 400);
    line-height: calc(16rem / 400);
    height: calc(40rem / 400);
    padding: calc(12rem / 400) calc(16rem / 400);
  }

  &.large-size {
    font-size: calc(16rem / 400);
    line-height: calc(16rem / 400);
    height: calc(48rem / 400);
    padding: calc(16rem / 400) calc(24rem / 400);
  }
  &.small-size {
    font-size: calc(14rem / 400);
    line-height: calc(20rem / 400);
    height: calc(32rem / 400);
    padding: calc(4rem / 400) calc(28rem / 400);
  }
  &.tiny-size {
    font-size: calc(12rem / 400);
    line-height: calc(16rem / 400);
    height: calc(32rem / 400);
    padding: calc(8rem / 400) calc(16rem / 400);
  }

  &.plain-button {
    text-decoration: underline;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
</style>
