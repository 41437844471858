<script setup lang="ts">
const props = defineProps({
  errorMessage: null,
  align: { default: 'right' },
  prefix: null,
  suffix: null,
  placeholder: null,
  type: null,
  disabled: { type: Boolean },
  precision: null
})

const emits = defineEmits(['blur'])

const modelValue = defineModel()
const inputRef = ref<HTMLInputElement>()

const customClass = computed(() => {
  const error = props.errorMessage ? 'error' : ''
  const disabled = props.disabled ? 'disabled' : ''
  return `${error} ${disabled}`
})
const inputCustomClass = computed(() => {
  const align = `text-${props.align}`
  return `${align}`
})

function onClickInput() {
  if (inputRef.value) {
    inputRef.value.focus()
  }
}

function onInput(event: Event) {
  if (props.precision) {
    const input = event.target as HTMLInputElement
    const value = input.value
    const selectionStart = input.selectionStart
    const decimalIndex = value.indexOf('.')
    if (decimalIndex !== -1) {
      const integerPart = value.slice(0, decimalIndex)
      const decimalPart = value.slice(decimalIndex + 1)

      if (decimalPart.length > +props.precision && selectionStart > decimalIndex) {
        input.value = (+`${value.slice(0, selectionStart - 1)}${value.slice(selectionStart)}`).toFixed(Math.max(+props.precision, 0))
      }
      if (integerPart.length > 1 && integerPart.startsWith('0')) {
        input.value = input.value.slice(1)
      }
    }
    input.value = input.value.replace(/[^\d.]/g, '')

    input.selectionStart = input.selectionEnd = Math.max(selectionStart + input.value.length - value.length, 0)
    modelValue.value = `${input.value}`
  }
}
</script>

<template>
  <div class="v-input-wrapper bg-black2 text-0.14" :class="customClass" @click="onClickInput">
    <div v-if="errorMessage" class="error-message rd-0.08 bg-grey1 px-0.12 py-0.06 color-white2">
      {{ errorMessage }}
      <div class="error-clip" />
    </div>
    <slot name="prefix">
      <div class="text-grey1">
        {{ prefix }}
      </div>
    </slot>
    <input
      ref="inputRef"
      v-model="modelValue"
      class="v-input"
      :class="inputCustomClass"
      :type="type"
      :disabled="disabled"
      :placeholder="placeholder"
      @input="onInput"
      @blur="emits('blur')"
    >
    <slot name="suffix">
      <div class="ml-0.04">
        {{ suffix }}
      </div>
    </slot>
  </div>
</template>

<style scoped>
.v-input-wrapper {
  border: 2px solid var(--vessel-color-black-3);
  border-radius: calc(12rem / 400);
  padding: 0 calc(16rem / 400);
  height: calc(48rem / 400);
  display: flex;
  align-items: center;
  position: relative;

  &:not(.disabled) {
    &:hover {
      border: 2px solid var(--vessel-color-grey-1);
    }
    &:focus-within, &:active {
      border: 2px solid var(--vessel-color-primary);
    }
  }
  &.error {
    border: 2px solid var(--vessel-color-red) !important;
  }
  .v-input {
    flex: 1;
    outline: none;
    border: none;
    font-size: calc(14rem / 400);
    line-height: calc(24rem / 400);
    height: calc(24rem / 400);
    padding: 0 calc(10rem / 400);
    font-weight: 500;
    background: transparent;
    caret-color: var(--vessel-color-primary);
    color: var(--vessel-color-white-2);
    text-align: right;
    min-width: 0;
    padding: 0;
    &.text-left {
      text-align: left;
    }
  }

  .error-message {
    position: absolute;
    right: 0;
    bottom: calc(54rem / 400);
    max-width: calc(200rem / 400);
    .error-clip {
      position: absolute;
      left: 50%;
      bottom: calc(-12rem / 400);
      transform: translate(-50%, 0);
      width: 0;
      height: 0;
      border: 7px solid transparent;
      border-top: 6px solid var(--vessel-color-grey-1);
    }
  }
}
</style>
