<script setup lang="ts">
import type { ChartOptions, DeepPartial, HistogramData, IChartApi, ISeriesApi, ITimeScaleApi, Time } from 'lightweight-charts'
import { ColorType, CrosshairMode, createChart } from 'lightweight-charts'
import type { PerformanceItem } from '@/service/modules/amm'

const props = defineProps({
  data: null,
  pKey: null
})

const isFirstShow = ref(true)

let chart: IChartApi
let areaSeries: ISeriesApi<'Area'>
// const histogramSeries = chart.addHistogramSeries({ color: '#26a69a' })
let timeScale: ITimeScaleApi<Time>

const chartOptions: DeepPartial<ChartOptions> = {
  rightPriceScale: {
    visible: false,
  },
  leftPriceScale: {
    visible: true,
  },
  layout: {
    textColor: '#777E90',
    background: {
      type: ColorType.Solid,
      color: '#19191d',
    },

    fontSize: 14,
    fontFamily: '',
  },
  grid: {
    vertLines: { visible: false },
    horzLines: { visible: false },
  },
  crosshair: {
    // Change mode from default 'magnet' to 'normal'.
    // Allows the crosshair to move freely without snapping to datapoints
    mode: CrosshairMode.Normal,

    // Vertical crosshair line (showing Date in Label)
    vertLine: {
      color: '#FFD87C',
      labelBackgroundColor: '#353945',
      labelVisible: false,
    },

    // Horizontal crosshair line (showing Price in Label)
    horzLine: {
      visible: false,
      labelVisible: false,
    },
  },
  localization: {
    locale: 'en-US',
    timeFormatter: (time: Time) => dayjs(+time as number).format('MM/DD'),
    priceFormatter: (price: any) => `$${price.toFixed(2)}`,
  },
}

const keyMap: Record<string, keyof PerformanceItem> = {
  TVL: 'tvl',
  Volume: 'volume',
  Fees: 'fee',
}

watch([computed(() => props.data), computed(() => props.pKey)], () => {
  const _data = props.data.map(i => ({
    time: dayjs(i.dt).valueOf() as Time,
    value: +i[keyMap[props.pKey]] as number,
  }))
  areaSeries.setData(_data)

  if (isFirstShow.value && _data.length) {
    // timeScale.fitContent()
    timeScale.setVisibleLogicalRange({ from: 0, to: _data.length })
    isFirstShow.value = false
  }

  const container = document.getElementById('performanceChart')

  const toolTipWidth = 120
  const toolTipHeight = 60
  const toolTipMargin = 15
  // Create and style the tooltip html element
  const toolTip = document.createElement('div')
  // @ts-expect-error will error
  toolTip.style = `width: ${toolTipWidth}px; height: ${toolTipHeight}px;
     position: absolute; display: none; padding: 6px 12px; box-sizing: border-box; font-size: 12px;
     text-align: left; z-index: 1000; top: 12px; left: 12px; pointer-events: none;
     border-radius: 8px;font-family: Poppins; paddding: 6px 12px; font-weight: 500;
     -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`
  toolTip.style.background = 'rgba(35, 38, 47, 0.80)'
  toolTip.style.color = '#E6E8EC'
  container.appendChild(toolTip)

  // update tooltip
  chart.subscribeCrosshairMove((param) => {
    if (
      param.point === undefined
        || !param.time
        || param.point.x < 0
        || param.point.x > container.clientWidth
        || param.point.y < 0
        || param.point.y > container.clientHeight
    ) {
      toolTip.style.display = 'none'
    }
    else {
      // time will be in the same format that we supplied to setData.
      // thus it will be YYYY-MM-DD
      const price = param.time as number
      toolTip.style.display = 'block'
      const data = param.seriesData.get(areaSeries) as HistogramData<Time>
      const amount = data?.value || 0

      if (price) {
        toolTip.innerHTML = `
      <div style="font-size: 12px; margin: 4px 0px;display: flex;" class="justify-center">
        $${formatNumberWithUnit(amount)}
      </div>
      <div style="font-size: 12px; margin: 4px 0px;display: flex;" class="justify-center">
        ${dayjs(+price).format('MM/DD/YYYY')}
      </div>

      `
      }

      const coordinate = areaSeries.priceToCoordinate(amount)
      let shiftedCoordinate = param.point.x + 20
      if (coordinate === null) {
        return
      }
      shiftedCoordinate = Math.max(
        0,
        Math.min(container.clientWidth - toolTipWidth, shiftedCoordinate),
      )
      toolTip.style.left = `${shiftedCoordinate + 60}px`
      toolTip.style.top = `${param.point.y + 20}px`
    }
  })
})

onMounted(() => {
  chart = createChart('performanceChart', chartOptions)
  areaSeries = chart.addAreaSeries({
    priceScaleId: 'left',
    topColor: '#37AAEC',
    bottomColor: '#006097',
    lineVisible: false,
    lastValueVisible: false,
    priceLineVisible: false,
    // lineColor: '#2962FF',
    // lineWidth: 2,
    // crossHairMarkerVisible: false,
  })

  areaSeries.priceScale().applyOptions({
    scaleMargins: {
      top: 0.2,
      bottom: 0,
    },

  })

  timeScale = chart.timeScale()
  timeScale.applyOptions({
    barSpacing: 0,
    // @ts-expect-error need this prop
    tickMarkFormatter: (time: Time) => {
      return dayjs(+time as number).format('MM/DD')
    },
  })
  timeScale.subscribeVisibleLogicalRangeChange((params) => {
    // console.log(params)

  })
})
</script>

<template>
  <div>
    <div id="performanceChart" class="relative h-2.31 w-10.74" />
  </div>
</template>

<style scoped>

</style>
