<script setup lang="ts">
import type { VoyageLeaderboardItem } from '~/service/modules/stats'
import type { Column } from '~/components/v/Table.vue'
import row1Img from '@/assets/image/voyage/row1.png'
import row2Img from '@/assets/image/voyage/row2.png'
import row3Img from '@/assets/image/voyage/row3.png'

const router = useRouter()
const { isSigned } = useKey()
const { isConnected } = useUser()
const { openModal } = useModal()
const { voyageSummary, getVoyageSummary } = useVoyage()

const leaderBoardList = ref<VoyageLeaderboardItem[]>([])

const { run: getVoyageLeaderboardList, isLoading } = useHttp(vesselApi.stats.getVoyageLeaderboard)

const tableColumns: Column[] = [
  {
    key: 'rank',
    title: 'Rank',
    minWidth: 30,
  },
  {
    key: 'userName',
    title: 'User',
    minWidth: 250,
    align: 'left',
  },
  {
    key: 'rankBoost',
    title: 'Navigator Boost',
    minWidth: 250,
    align: 'right',
  },
  {
    key: 'lpPoint24h',
    title: 'Vessel Mileage(24h)',
    minWidth: 250,
    align: 'right',
  },
  {
    key: 'lpPointTotal',
    title: 'Total Vessel Mileage',
    minWidth: 250,
    align: 'right',
  },
]

const leaderBoardListWithUser = computed(() => {
  if (!isSigned.value) {
    return leaderBoardList.value
  }
  if ((voyageSummary.value?.lpPointRank && +voyageSummary.value?.lpPointRank > 3) || !voyageSummary.value?.lpPointRank) {
    return [
      {
        lpPoint24h: voyageSummary.value.lpPoint24h,
        lpPointRank: voyageSummary.value.lpPointRank || '-',
        lpPointTotal: voyageSummary.value.lpPointTotal,
        rankBoost: voyageSummary.value.rankBoost,
        userName: voyageSummary.value.userName,
        rowClass: 'bg-black2 rd-0.04',
      },
      ...leaderBoardList.value,
    ]
  }

  return leaderBoardList.value
})

onMounted(() => {
  getVoyageLeaderboardList().then((res) => {
    leaderBoardList.value = res.data.data
  })
  getVoyageSummary()
})
</script>

<template>
  <div class="bg mb-0.4 rd-0.12 px-0.32 py-0.32">
    <div class="text-body2b text-grey3">
      Navigator Leaderboard
    </div>
    <div class="my-0.12 text-caption1b text-grey1">
      Navigator leaderboard will be boosting the top 100 users for their Vessel Mileage earned during the past 24hrs.
      <span class="cursor-pointer text-primary underline" @click="openUrl('https://vesselfinance.gitbook.io/vessel/vessel-voyage/vessel-mileage-for-liquidity-providing#what-is-navigator-boost')">Learn more</span>
    </div>
    <v-table
      class="min-h-3 overflow-auto"
      :data="leaderBoardListWithUser"
      :columns="tableColumns"
      :loading="isLoading"
      row-key="time"
      content-class="gap-y-0.08  min-h-2.8 "
      row-class="gap-x-0.16 py-0.04 h-0.52 text-0.16 px-0.16"
      title-row-class="gap-x-0.16 text-caption2b py-0.04 px-0.16"
    >
      <template #rank="{ data: { row } }">
        <img v-if="+row.lpPointRank === 1" class="ml--0.16 w-0.42" :src="row1Img" alt="">
        <img v-else-if="+row.lpPointRank === 2" class="ml--0.16 w-0.42" :src="row2Img" alt="">
        <img v-else-if="+row.lpPointRank === 3" class="ml--0.16 w-0.42" :src="row3Img" alt="">
        <div v-else class="pl-0.08 text-body2b">
          {{ row.lpPointRank }}
        </div>
      </template>
      <template #userName="{ data: { row } }">
        <div class="text-caption1">
          {{ row.userName }}<span v-if="row.userName === voyageSummary?.userName" class="ml-0.02 text-primary">(YOU)</span>
        </div>
      </template>
      <template #rankBoost="{ data: { row } }">
        <div class="text-caption1">
          {{ row.rankBoost }}x
        </div>
      </template>
      <template #lpPoint24h="{ data: { row } }">
        <div class="text-caption1">
          {{ formatNumber(row.lpPoint24h, 0) }}
        </div>
      </template>
      <template #lpPointTotal="{ data: { row } }">
        <div class="text-caption1">
          {{ formatNumber(row.lpPointTotal, 0) }}
        </div>
      </template>
    </v-table>
  </div>
</template>

<style scoped>
.bg {
  border: 0.4px solid var(--Neutrals-black-3, #353945);
  background: rgba(20, 20, 22, 0.50);
  backdrop-filter: blur(10px);
}
</style>
