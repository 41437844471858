<script setup lang="ts">
import { useAsyncValidator } from '@vueuse/integrations/useAsyncValidator'
import type { Column } from '~/components/v/Table.vue'
import type { OrderItem, PlaceOrderRequest } from '~/service/modules/order'
import type { SymbolItem } from '~/service/modules/public'

const props = defineProps({
  historyCurrentSymbol: null
})
const { currentSymbol, symbolList } = useSymbol()
const { assetBalance, symbolPrice, isNeedRefresh } = useWs()

const { address, preference } = useUser()
const { isSigned } = useKey()
const orderSymbol = ref<SymbolItem>()
const orderSymbolBalanceItem = computed(() => symbolPrice.value?.[orderSymbol.value.symbol])
const { run: modifyOrder, isLoading: isLoadingSubmit } = useHttp(vesselApi.order.modifyOrder)
const { run: cancelAllOrder, isLoading: isLoadingCancelAll } = useHttp(vesselApi.order.cancelAllOrder)

const message = useMessage()
const notification = useNotification()
const isOnlyCurrent = useStorage('isOnlyCurrentOpen', false)
const showModify = ref(false)
const isValidate = ref(false)
const openOrderList = ref<OrderItem[]>([])

const { run: getApiOpenOrder, isLoading } = useHttp(vesselApi.order.getOpenOrder)

const filter = ref({
  type: null,
  side: null,
  range: [dayjs().subtract(6, 'days').valueOf(), dayjs().valueOf()],
  symbol: null,
})

const noMore = ref(false)
const page = ref(1)
const showCancelAllModal = ref(false)

const sideList = ['Buy', 'Sell']
const symbolFilterList = computed(() => (symbolList.value.map(i => addSymbolSlash(i.symbol))))

const tableColumns: Column[] = [
  {
    key: 'symbol',
    title: 'Symbol',
    minWidth: 80,
  },
  {
    key: 'time',
    title: 'Order Time',
    minWidth: 80,
    align: 'left',
  },
  {
    key: 'type',
    title: 'Type',
    minWidth: 60,
  },
  {
    key: 'side',
    title: 'Side',
    minWidth: 40,
  },
  {
    key: 'price',
    title: 'Filled | Order Price',
    minWidth: 150,
    align: 'right',
  },
  {
    key: 'amount',
    title: 'Filled | Order Amount',
    minWidth: 150,
    align: 'right',
  },
  {
    key: 'total',
    title: 'Filled | Total',
    minWidth: 150,
    align: 'right',
  },
  {
    key: 'status',
    title: 'Status',
    minWidth: 80,
    width: 80,
    align: 'right',
  },
  {
    key: 'cancel',
    title: '',
    width: 200,
    minWidth: 200,
    align: 'right',
  },
]

function getOpenOrder(isReset = false) {
  getApiOpenOrder({
    fromId: openOrderList.value.length ? openOrderList.value.at(-1)?.orderId : undefined,
    limit: 500,
    startTime: filter.value.range[0],
    endTime: dayjs(filter.value.range[1]).endOf('day').valueOf(),
    side: filter.value.side?.toUpperCase(),
    type: filter.value.type?.toUpperCase(),
    symbol: filter.value.symbol?.replace('/', ''),
  }).then((res) => {
    if (isReset) {
      openOrderList.value = []
    }
    noMore.value = res.data.orders.length < ORDER_PAGE_SIZE
    openOrderList.value.push(...res.data.orders)
  })
}

const form = ref<{
  price: string
  amount: string
  side?: 'SELL' | 'BUY'
  oldOrderId?: string
  symbol?: string
  available?: number
  base?: string
}>({
  price: '',
  amount: '',
})
const { pass, isFinished, errorFields, execute: validateFields } = useAsyncValidator(form, computed(() => ({
  price: [
    {
      pattern: new RegExp(`^\\d+(\\.\\d{1,${getPrecisionFromSymbol(form.value?.symbol).price}})?$`),
      message: `Number decimals must be under ${getPrecisionFromSymbol(form.value?.symbol).price}`,
    },
    {
      validator(rule: any, value: any) {
        return +value > 0
      },
      message: 'Price is required',
    },
  ],
  amount: [
    {
      validator(rule: any, value: any) {
        return +value > 0
      },
      required: true,
      message: 'Amount is required',
    },
    {
      pattern: new RegExp(`^\\d+(\\.\\d{1,${getPrecisionFromSymbol(form.value?.symbol).amount}})?$`),
      message: `Number decimals must be under ${getPrecisionFromSymbol(form.value?.symbol).amount}`,
    },
  ],
})), {
  manual: true,
})

function onCancelOrder(order: OrderItem) {
  vesselApi.order.cancelOrder({
    orderId: +order.orderId,
    symbol: order.symbol,
    address: address.value,
  }).then((res) => {
    getOpenOrder(true)
    message.success('Order Cancelled.')
  })
}

function getErrorMessage(field: string) {
  return isValidate.value ? errorFields.value[field]?.[0]?.message : ''
}

function onModifyOrder(order: OrderItem) {
  showModify.value = true
  orderSymbol.value = symbolList.value.find(i => i.symbol === order.symbol)

  const availableBalance = (assetBalance.value?.find(i => i.assetName === (order.side.toUpperCase() === 'BUY' ? orderSymbol.value.quoteAssetName : orderSymbol.value.baseAssetName))?.available) || 0
  form.value = {
    side: order.side,
    oldOrderId: order.orderId,
    symbol: order.symbol,
    amount: `${order.origQty}`,
    price: `${order.price}`,
    available: order.side.toUpperCase() === 'BUY' ? +availableBalance + +order.origQty * +order.price : +availableBalance + +order.origQty,
    base: orderSymbol.value.baseAssetName,
  }
}

async function handleModifyOrder() {
  isValidate.value = true
  await validateFields()
  if (!pass.value) {
    return
  }

  if (
    (form.value.side === 'BUY' && +form.value.amount * +form.value.price > +form.value.available)
    || (form.value.side === 'SELL' && +form.value.amount > +form.value.available)
  ) {
    message.error('Insufficient available amount.')
    return
  }
  const order: Omit<PlaceOrderRequest, 'signature'> = {
    symbol: form.value.symbol,
    side: form.value.side,
    type: 'LIMIT',
    timeInForce: 'GTC',
    quantity: +form.value.amount,
    price: +form.value.price,
    clientOrderId: `${new Date().getTime()}`,
  }
  const { signature, timestamp } = await signOrder(order)
  modifyOrder({
    newClientOrderId: `${timestamp}`,
    oldOrderId: form.value.oldOrderId,
    price: +form.value.price,
    quantity: +form.value.amount,
    signature,
    symbol: form.value.symbol,
  }, {
    headers: {
      'VESSEL-TIMESTAMP': timestamp,
    },
  }).then((res) => {
    showModify.value = false
    getOpenOrder(true)
    if (preference.value.tradeNotification) {
      notification.success({
        title: 'Submit Order',
        duration: 2000,
        content: () => h('div', {}, {
          default: () => [
            h('div', {}, { default: () => 'Order modify successfully' }),
            h('div', {}, { default: () => `${form.value.side.toLocaleUpperCase()} ${form.value.base} with price: ${form.value.price}` }),
            h('div', {}, { default: () => `Amount: ${form.value.amount} ${form.value.base}` }),
          ],
        }),
      })
    }
  })
}

function reset() {
  filter.value = {
    type: 'All',
    side: 'All',
    range: null,
    symbol: null,
  }
}

function onClickCancelAll() {
  if (preference.value.cancelAllConfirmation) {
    showCancelAllModal.value = true
  }
  else {
    cancelAll()
  }
}

function cancelAll() {
  cancelAllOrder({
    symbol: filter.value.symbol?.replace('/', ''),
  }).then((res) => {
    message.success('Order cancelled.')
    showCancelAllModal.value = false
  })
}

function handleLoad() {
  if (isLoading.value || noMore.value)
    return
  getOpenOrder()
}

watch(isNeedRefresh, () => {
  getOpenOrder(true)
})

watch([isOnlyCurrent, props], () => {
  if (props.historyCurrentSymbol) {
    filter.value.symbol = isOnlyCurrent.value ? props.historyCurrentSymbol : null
  }
}, {
  immediate: true,
})

watch(filter, () => {
  getOpenOrder(true)
}, {
  deep: true,
})

whenever(isSigned, () => {
  getOpenOrder(true)
}, {
  immediate: true,
})
</script>

<template>
  <div class="relative w-full flex-1 overflow-hidden">
    <div class="flex justify-between">
      <n-config-provider :theme-overrides="selectBorderOverrides">
        <div class="ml-0.04 flex items-center gap-x-0.12">
          <n-date-picker
            v-model:value="filter.range"
            start-placeholder="MM/DD/YYYY"
            end-placeholder="MM/DD/YYYY"
            type="daterange"
            size="small"
            format="MM/dd/yyyy"
          >
            <template #date-icon>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M13.332 2.00033H12.6654V0.666992H11.332V2.00033H4.66536V0.666992H3.33203V2.00033H2.66536C1.93203 2.00033 1.33203 2.60033 1.33203 3.33366V14.0003C1.33203 14.7337 1.93203 15.3337 2.66536 15.3337H13.332C14.0654 15.3337 14.6654 14.7337 14.6654 14.0003V3.33366C14.6654 2.60033 14.0654 2.00033 13.332 2.00033ZM13.332 14.0003H2.66536V6.66699H13.332V14.0003ZM13.332 5.33366H2.66536V3.33366H13.332V5.33366Z" fill="#777E91" />
              </svg>
            </template>
          </n-date-picker>
          <n-select
            v-if="!historyCurrentSymbol"
            v-model:value="filter.symbol" class="w-1.34"
            placeholder="Symbol" clearable
            :options="symbolFilterList.map(i => ({ value: i, label: i }))"
          />
          <n-select
            v-model:value="filter.side" class="w-1.34"
            placeholder="Side" clearable
            :options="sideList.map(i => ({ value: i, label: i }))"
          />
          <!--          <div class="cursor-pointer text-0.14 font-500 font-dm text-white2" @click="reset"> -->
          <!--            Reset -->
          <!--          </div> -->
        </div>
      </n-config-provider>
      <n-checkbox v-if="historyCurrentSymbol" v-model:checked="isOnlyCurrent">
        Current Pair Only
      </n-checkbox>
    </div>
    <v-table
      class="mt-0.12 min-h-3.2 overflow-auto"
      :data="openOrderList"
      :columns="tableColumns"
      :column-gap="35"
      :loading="isLoading"
      row-key="time"
      content-class="gap-y-0.08 min-h-2.8"
      row-class="gap-x-0.16 py-0.04 h-0.4"
      title-row-class="gap-x-0.16"
    >
      <template #noData>
        <div>You have no open orders.</div>
      </template>
      <template #symbol="{ data: { row } }">
        {{ addSymbolSlash(row.symbol) }}
      </template>
      <template #title-cancel>
        <div class="cursor-pointer text-0.12 text-primary font-600" @click="onClickCancelAll">
          Cancel all
        </div>
      </template>
      <template #time="{ data: { row } }">
        <div>
          {{ dayjs(+row.orderTime).format('MM/DD/YYYY') }}
        </div>
        <div>
          {{ dayjs(+row.orderTime).format('HH:mm:ss') }}
        </div>
      </template>
      <template #price="{ data: { row } }">
        <div>
          <div>{{ +row.executedQty !== 0 ? formatNumber(+row.cumulativeQuoteQty / +row.executedQty, 2) : '-' }}</div>
          <div>{{ row.type === 'MARKET' ? 'MARKET' : formatNumber(row.price, 2) }}</div>
        </div>
      </template>
      <template #total="{ data: { row } }">
        <div>
          <div>{{ formatNumber(row.cumulativeQuoteQty, 2) }}</div>
          <div>{{ formatNumber(+row.price * +row.origQty) }}</div>
        </div>
      </template>
      <template #amount="{ data: { row } }">
        <div>
          <div>{{ formatNumber(row.executedQty, 4) }}</div>
          <div>{{ formatNumber(row.origQty, 4) }}</div>
        </div>
      </template>
      <template #side="{ data: { row } }">
        <div class="capitalize" :class="{ 'text-green': row.side === 'BUY', 'text-red': row.side === 'SELL' }">
          {{ row.side?.toLowerCase() }}
        </div>
      </template>
      <template #type="{ data: { row } }">
        <div class="capitalize">
          {{ row.type?.toLowerCase() }}
        </div>
      </template>
      <template #status="{ data: { row } }">
        <div>
          {{ row.status?.replace(/\_/g, ' ') }}
        </div>
      </template>
      <template #cancel="{ data: { row } }">
        <div class="flex items-center justify-end gap-x-0.08">
          <v-button size="tiny" type="outline" @click="onModifyOrder(row)">
            Modify
          </v-button>
          <v-button size="tiny" type="outline" @click="onCancelOrder(row)">
            Cancel
          </v-button>
        </div>
      </template>
    </v-table>

    <v-modal
      v-model:show="showModify"
      modal-class="w-4.48"
      title="Order Modify"
    >
      <div class="mt-0.22 flex items-center text-body2 text-grey1">
        <v-icon :currency="orderSymbol.baseAssetName" class="mr-0.04 h-0.2 w-0.2" />
        <span class="text-white2">{{ orderSymbol.baseAssetName }}</span><span>/{{ orderSymbol.quoteAssetName }}</span>
      </div>
      <div class="mt-0.08 text-caption2b text-grey1">
        <span>Last Price</span>
        <span class="ml-0.08 text-white2">{{ formatNumber(orderSymbolBalanceItem?.closePrice, 2) }} {{ orderSymbol.quoteAssetName }}</span>
      </div>

      <div class="mb-0.14 mt-0.32 text-0.16 text-grey2">
        Price({{ orderSymbol.quoteAssetName }})
      </div>
      <v-input
        v-model="form.price" :error-message="getErrorMessage('price')"
        :precision="getPrecisionFromSymbol(orderSymbol.symbol).price"
        @input="isValidate = false"
      />
      <div class="mb-0.14 mt-0.32 text-0.16 text-grey2">
        Amount({{ orderSymbol.baseAssetName }})
      </div>
      <v-input v-model="form.amount" :error-message="getErrorMessage('amount')" @input="isValidate = false" />

      <template #footer>
        <v-button
          class="mt-0.32 w-full"
          :loading="isLoadingSubmit"
          @click="handleModifyOrder"
        >
          Modify
        </v-button>
      </template>
    </v-modal>
    <v-modal v-model:show="showCancelAllModal" title="Cancel Order Confirmation" :z-index="201">
      <div class="text-grey1 font-400">
        Are you sure you want to cancel All {{ filter.symbol || '' }} open orders?
      </div>
      <template #footer>
        <div class="mt-0.32 flex gap-x-0.16">
          <v-button class="flex-1" bg="red" :loading="isLoadingCancelAll" @click="cancelAll">
            Confirm
          </v-button>
        </div>
      </template>
    </v-modal>
  </div>
</template>

<style scoped>

</style>
