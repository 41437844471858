import BN from 'bignumber.js'

export async function clearAddressInfo() {
  const { clearUserInfo, initAddress } = useUser()
  const { assetBalance, closeAssetBalanceWs } = useWs()
  const { currentOpenOrderList } = useOrder()
  const { clearKey } = useKey()
  const { closeAllModal } = useModal()
  clearUserInfo()
  clearKey()
  closeAssetBalanceWs()
  currentOpenOrderList.value = []
  assetBalance.value = []
  initAddress.value = ''
  // closeAllModal()
}

export async function disconnectWallet() {
  const { initAddress } = useUser()
  const registeredAddress = useStorage('REGISTERED_ADDRESS', [])
  const connector = useConnector()
  const { clearKey } = useKey()
  clearKey(initAddress.value)
  await connector.disconnect()
  registeredAddress.value = registeredAddress.value.filter(i => i !== initAddress.value)
  clearAddressInfo()
}

export function initNewWallet(address: `0x${string}`) {
  const { initUserInfo, clearUserInfo, userInfo, initAddress, initStatsUserInfo, invitation } = useUser()
  const { initKey } = useKey()
  const { getFaucetInfo } = useFaucet()
  const { subscribeBalance } = useWs()

  if (initAddress.value === address || !address) {
    return
  }
  clearAddressInfo()
  const isSigned = initKey(address)
  if (isSigned) {
    if (location.pathname === '/login') {
      invitation.value.isValid = true
      location.href = '/trade'
    }
    nextTick(() => {
      initUserInfo().then(() => {
        initStatsUserInfo()
      })
      getFaucetInfo()
      subscribeBalance()
      initAddress.value = address
    })
  }
  else {
    setTimeout(() => {
      checkAndBindVesselKey()
    }, 500)
  }
}

export function checkAndBindVesselKey() {
  const { run: verifyAddress } = useHttp(vesselApi.stats.verifyAddress)
  const { address, invitation, initStatsUserInfo } = useUser()
  const { getVesselKey } = useKey()
  const { openModal } = useModal()

  if (!address.value) {
    return
  }

  verifyAddress({
    address: address.value,
  }).then((res) => {
    if (!res.data.isValid) {
      if (!invitation.value.code) {
        openModal('walletNotBound')
      }
      else {
        invitation.value.isNewCode = true
        getVesselKey()
      }
    }
    else {
      invitation.value.isNewCode = false
      invitation.value.isValid = true
      invitation.value.code = ''
      if (location.pathname === '/login') {
        location.href = '/trade'
      }
      getVesselKey()
    }
  })
}

export function getCurrencyFromSymbol(symbol: string) {
  const { symbolList } = useSymbol()
  const symbolItem = symbolList.value.find(i => i.symbol === symbol)
  return symbolItem
}

export function getPrecisionFromSymbol(symbol: string) {
  const { tickerInfo } = useSymbol()
  const tickerItem = tickerInfo.value.find(i => i.symbol === symbol)
  if (!tickerItem) {
    return {
      price: 2,
      total: 4,
      base: 2,
      value: 4,
      amount: 2,
    }
  }
  return {
    price: +tickerItem.quoteAssetPrecision - +tickerItem.baseAssetPrecision,
    total: +tickerItem.quoteAssetPrecision,
    value: +tickerItem.quoteAssetPrecision,
    quote: +tickerItem.quoteAssetPrecision,
    amount: +tickerItem.baseAssetPrecision,
    base: +tickerItem.baseAssetPrecision,
  }
}

export function getUserFee(symbol: string) {
  const { userInfo } = useUser()

  const { tickerInfo, feeInfo } = useSymbol()

  if (!symbol || !userInfo.value) {
    const item = feeInfo.value.find(i => i.level === 'DEFAULT')
    return {
      takerFee: +item?.takerFeeRate || 0,
      makerFee: +item?.makerFeeRate || 0,
    }
  }
  const tickerItem = tickerInfo.value?.find(i => i.symbol === symbol)

  if (!tickerItem) {
    return {
      takerFee: +userInfo.value.takerFeeRate,
      makerFee: +userInfo.value.makerFeeRate,
    }
  }
  return {
    takerFee: +BN(userInfo.value.takerFeeRate).multipliedBy(tickerItem.takerFeeDiscount).dividedBy(BN(100)).toFixed(6),
    makerFee: +BN(userInfo.value.makerFeeRate).multipliedBy(tickerItem.makerFeeDiscount).dividedBy(BN(100)).toFixed(6),
  }
}
