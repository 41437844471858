import type { App } from 'vue'
import { WagmiPlugin, createConfig, http } from '@wagmi/vue'
import { QueryClient, VueQueryPlugin } from '@tanstack/vue-query'
import { metaMask, walletConnect } from '@wagmi/vue/connectors'
import type { Chain } from 'viem'
import type { Config } from '@wagmi/core'

let wagmiConfig: Config

export function getWagmiConfig() {
  const chainConfig = getChainConfigSync()
  if (wagmiConfig) {
    return wagmiConfig
  }

  return wagmiConfig = createConfig({
    pollingInterval: 2000,
    chains: chainConfig.map(c => ({
      id: Number(c.id),
      name: c.name,
      nativeCurrency: {
        name: c.nativeCurrencyName,
        symbol: c.nativeCurrencySymbol,
        decimals: Number(c.nativeCurrencyDecimals),
      },
      rpcUrls: {
        default: {
          http: [c.publicRpcUrl],
        },
      },
      blockExplorers: {
        default: {
          name: c.explorerName,
          url: c.explorerUrl,
        },
      },
    })) as unknown as readonly [Chain, ...Chain[]],
    transports: chainConfig.reduce((res, i) => ({ ...res, [i.id]: http() }), {}),
    connectors: [
      metaMask({ enableAnalytics: false }),
      walletConnect({ projectId: WALLET_CONNECT_PROJECT_ID }),
      // injected({
      //   target() {
      //     return {
      //       id: 'okxwallet',
      //       name: 'OKX Wallet',
      //       provider: window.okxwallet,
      //     }
      //   },
      // }),
      // injected({
      //   target() {
      //     return {
      //       id: 'rabby',
      //       name: 'Rabby Wallet',
      //       provider: window.rabby,
      //     }
      //   },
      // }),
    ],
  })
}

export function installWagmi(app: App) {
  const config = getWagmiConfig()
  const queryClient = new QueryClient()
  app.use(WagmiPlugin, { config })
  app.use(VueQueryPlugin, { queryClient })
}

// declare module '@wagmi/vue' {
//   interface Register {
//     config: typeof config
//   }
// }
